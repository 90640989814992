import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'

import s from './complete.module.css'

export default function ApplicationComplete() {
  return (
    <Layout header="wholesale">
      <SEO
        title="Wholesale Account Sign Up Process | Chalk UK"
        description="Wholesale Sign Up Process. Become a stockist of Chalk's products."
      />
      <div className={s.wrapper}>
        <div className={s.container}>
          <h1 className={s.title}>Your application has been submitted</h1>
          <p className={s.intro}>Thank you for applying</p>
        </div>
      </div>
      <div className={s.description}>
        <p>Applications take around 2 weeks to process.</p>
        <p>Depending on your store type we will review:</p>
        <ul>
          <li>Postcode availability</li>
          <li>The products you stock on your online store</li>
        </ul>
        <p>
          It&apos;s really important for us to build long term & successful
          relationships with our stockists. If you have any questions or feel
          that further details would be useful for us to review, please{' '}
          <Link to="/contact" className={s.linkUnderline}>
            contact us
          </Link>
          .
        </p>
      </div>
    </Layout>
  )
}
